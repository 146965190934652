<template>
  <div class="flex items-center gap-1 md:gap-2">
    <Image
      v-if="img"
      :src="img"
      :alt="label"
      loading="lazy"
      :width="isMd ? 22 : 16"
      :height="isMd ? 22 : 16"
    />
    <span
      :class="
        size === 'sm'
          ? 'text-detail-15-regular-mobile'
          : 'text-button-18-regular-mobile md:text-button-20-regular'
      "
    >
      {{ label }}
    </span>
  </div>
</template>

<script setup lang="ts">
import screens from '#tailwind-config/theme/screens'

export interface DropdownOptionProps {
  img?: string
  label: string
  size?: 'sm' | 'md'
}

withDefaults(defineProps<DropdownOptionProps>(), {
  size: 'md'
})

const breakpoints = useBreakpoints(screens)
const isMd = breakpoints.greater('md')
</script>
